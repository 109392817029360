import { Theme } from './models';
import { PersistentStorage } from './PersistentStorage';

type ThemeButtons = {
   [k in Theme]: HTMLButtonElement;
};

export class ThemeSwitcher {
   private readonly buttons: ThemeButtons;

   constructor(private persistentStorage: PersistentStorage, private document: Document, private el: HTMLDivElement) {
      if (!this.el) {
         throw new Error('element is expected in ThemeSwitcher.constructor');
      }

      this.buttons = {
         [Theme.Dark]: el.querySelector('[name="dark"]') as HTMLButtonElement,
         [Theme.Light]: el.querySelector('[name="light"]') as HTMLButtonElement,
      };
   }

   public run() {
      this.el.hidden = false;
      this.setTheme(this.getTheme());

      this.addClickHandlers();
   }

   private addClickHandlers() {
      for (const theme in this.buttons) {
         if (this.buttons.hasOwnProperty(theme)) {
            const button = this.buttons[theme] as HTMLButtonElement;
            button.addEventListener('click', () => this.setTheme(theme as Theme));
         }
      }
   }

   private getTheme(): Theme {
      const fromStorage = this.persistentStorage.getTheme();

      if (fromStorage && Object.values(Theme).includes(fromStorage)) {
         return fromStorage;
      }

      return window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.Dark : Theme.Light;
   }

   private setTheme(newTheme: Theme) {
      this.persistentStorage.setTheme(newTheme);

      for (const theme in this.buttons) {
         if (this.buttons.hasOwnProperty(theme)) {
            const button = this.buttons[theme] as HTMLButtonElement;
            if (theme === newTheme) {
               button.classList.add('active');
               button.disabled = true;
               this.document.body.classList.add(`theme-${theme}`);
            } else {
               button.classList.remove('active');
               button.disabled = false;
               this.document.body.classList.remove(`theme-${theme}`);
            }
         }
      }
   }
}
