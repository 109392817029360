import { PersistentStorage } from './PersistentStorage';
import { ThemeSwitcher } from './ThemeSwitcher';

export class App {
   private document = this.window.document;

   private readonly persistentStorage = new PersistentStorage();

   private themeSwitcher: ThemeSwitcher;

   constructor(private window: Window) {
      this.themeSwitcher = new ThemeSwitcher(
         this.persistentStorage,
         this.document,
         this.document.querySelector('#js-theme-switcher'),
      );
   }

   public run() {
      this.setScrollbarWidth();

      this.themeSwitcher.run();
   }

   public setScrollbarWidth() {
      this.document.body.style.setProperty('--scrollbar-width', this.getScrollbarWidth() + 'px');
   }

   private getScrollbarWidth() {
      return this.window.innerWidth - this.window.document.documentElement.clientWidth;
   }
}
