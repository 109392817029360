import { Theme } from './models';

export class PersistentStorage {
   public getTheme(): Theme | null {
      return localStorage.getItem('theme') as Theme | null;
   }

   public setTheme(theme: Theme): void {
      localStorage.setItem('theme', theme);
   }
}
